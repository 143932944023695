import moment from 'moment-timezone'

import {EquipmentExpertSystemUsage, EquipmentExpertSystemUsageDto} from '../types'

export const mapEquipmentExpertSystemUsages = (
  data: EquipmentExpertSystemUsageDto[],
  timezone: string
): EquipmentExpertSystemUsage[] =>
  data.map((usage: EquipmentExpertSystemUsageDto) => ({
    ...usage,
    createdAt: moment.utc(usage.createdAt).tz(timezone)
  }))
