import {useEquipmentNumberSearchQuery} from './useEquipmentNumberSearchQuery'
import {useMainEquipmentNumberSearchQuery} from './useMainEquipmentNumberSearchQuery'

export const useMainEquipmentFilterOptionsSearch = (plantId: string, searchQuery?: string) => {
  const {data: mainEquipments = [], isLoading: isLoadingMainEquipments} =
    useMainEquipmentNumberSearchQuery(searchQuery, plantId)
  const {data: additionalEquipments = [], isLoading: isLoadingAdditional} =
    useEquipmentNumberSearchQuery(searchQuery, plantId)
  const sapMainEquipment = additionalEquipments
    .filter((e) => Array.isArray(e.path) && e.path.length === 0)
    .map((item) => item.equipment)

  const options = mainEquipments.length > 0 ? mainEquipments : sapMainEquipment
  const isLoading = isLoadingMainEquipments || isLoadingAdditional

  return {data: options, isLoading}
}
