import {AxiosError} from 'axios'
import {UseQueryOptions} from 'react-query'

export const mockQueryReturn = <T>(
  response: T,
  reactQueryData?: Partial<UseQueryOptions<T, AxiosError<Error>>>
) => ({
  data: response,
  error: null,
  isLoading: false,
  ...reactQueryData
})
