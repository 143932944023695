import {customThemeConstants} from '@hconnect/uikit/src/lib2'
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  FilterList as FilterIcon,
  InfoOutlined as InfoOutlinedIcon
} from '@mui/icons-material'
import {Box, Button, Menu, MenuItem, Theme, Tooltip} from '@mui/material'
import React from 'react'

export type Option<T> = {
  value: T
  label: string
  disabled?: boolean
  tooltipTitle?: React.ReactNode
}

type AddFilterButtonProps<T> = {
  onChange: (newValue: T[]) => void
  id?: string
  'data-test-id': string
  values: T[]
  options: Option<T>[]
  translationPrefix?: string
  buttonLabel: string
}

export function AddFilterButton<T extends string>({
  values,
  onChange,
  options,
  id,
  'data-test-id': dataTestId,
  buttonLabel
}: AddFilterButtonProps<T>) {
  const [buttonElement, setButtonElement] = React.useState<null | HTMLElement>(null)

  const handleOpen = ({currentTarget}: React.MouseEvent<HTMLButtonElement>) => {
    setButtonElement(currentTarget)
  }

  const handleClose = () => {
    setButtonElement(null)
  }

  const handleClick = (optionValue: T) => {
    const selectedValues: T[] = values.includes(optionValue)
      ? values.filter((selectedOptionValue) => selectedOptionValue !== optionValue)
      : [...values, optionValue]

    onChange(selectedValues)
  }

  const open = Boolean(buttonElement)

  return (
    <>
      <Button
        data-test-id={dataTestId}
        onClick={handleOpen}
        variant="text"
        id={id}
        startIcon={<FilterIcon />}
        sx={(theme: Theme) => ({
          border: 1,
          borderColor: customThemeConstants().palette.mediumInputBorder,
          color: theme.palette.common.white,
          padding: 2
        })}
      >
        {buttonLabel}
      </Button>

      <Menu
        anchorEl={buttonElement}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {options.map(({value, label, tooltipTitle, disabled}) => (
          <Tooltip title={tooltipTitle} key={value} placement="top">
            <span>
              <MenuItem
                data-test-id={`${dataTestId}-${value}`}
                id={`${dataTestId}-${value}`}
                key={value}
                value={value}
                onClick={() => handleClick(value)}
                disabled={disabled}
                sx={{width: '100%', justifyContent: 'space-between'}}
              >
                <Box sx={{display: 'flex'}}>
                  {values.includes(value) ? (
                    <CheckBoxIcon sx={{mr: 1}} />
                  ) : (
                    <CheckBoxOutlineBlankIcon sx={{mr: 1}} />
                  )}
                  {label}
                </Box>
                {disabled && <InfoOutlinedIcon sx={{ml: 1, justifySelf: 'flex-end'}} />}
              </MenuItem>
            </span>
          </Tooltip>
        ))}
      </Menu>
    </>
  )
}
