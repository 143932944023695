import {isEmpty} from 'lodash'

import {FeatureDefinition} from '../types'

export const isFeatureSupported = (feature: FeatureDefinition | undefined, plantId: string) => {
  if (feature) {
    const isAllowed = !isEmpty(feature.whitelist) ? feature.whitelist.includes(plantId) : true
    const isDeclined = !isEmpty(feature.blacklist) ? feature.blacklist.includes(plantId) : false
    return isAllowed && !isDeclined
  }
  return false
}
