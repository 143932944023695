import {ProcessStage} from '../types'
import {mockQueryReturn} from '../utils'

const plantId = 'NotUsedJet'

export const sampleProcessStagesEN: ProcessStage[] = [
  {plantId, processStep: '10', processStepValue: '10 - Quarry'},
  {plantId, processStep: '20', processStepValue: '20 - Crusher'},
  {plantId, processStep: '30', processStepValue: '30 - Raw Mill'},
  {plantId, processStep: '40', processStepValue: '40 - Kiln'},
  {plantId, processStep: '50', processStepValue: '50 - Grinding'},
  {plantId, processStep: '60', processStepValue: '60 - Dispatch'}
]

export const processStageData = mockQueryReturn<Map<string, string>>(
  new Map(
    sampleProcessStagesEN.map((stage: ProcessStage) => {
      return [stage.processStep, stage.processStepValue]
    })
  )
)
