import {ExpandCollapseButton} from '@hconnect/uikit/src/lib2'
import {AxiosError} from 'axios'
import React, {memo, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {MaterialStorage, MaterialVM} from '../../types'
import {ContentBox, ContentMode} from '../ContentBox'

import {Silos} from './Silos'

const collapsedItemCount = 10

type Props = {
  contentMode: ContentMode
  isLoading?: boolean
  error?: AxiosError | null
  materials?: MaterialVM[]
  materialStorageData?: MaterialStorage[]
}

export const MaterialStorageContainer: React.FC<Props> = memo(
  ({contentMode, isLoading, error, materialStorageData, materials}) => {
    const {t} = useTranslation()
    const [expanded, setExpanded] = useState(false)
    const silos =
      (expanded ? materialStorageData : materialStorageData?.slice(0, collapsedItemCount)) || []

    const errorMessage = error ? t('error.label.fetchingError') : undefined

    return (
      <ContentBox
        title={t('materialStorage.label.SiloFillLevels')}
        mode={contentMode}
        bodyWithPadding
        isLoading={isLoading}
        minHeight="450px"
        errorMessage={errorMessage}
        data-test-id={'material-storage-container'}
        afterTitle={
          materialStorageData && materialStorageData.length > collapsedItemCount ? (
            <ExpandCollapseButton
              expanded={expanded}
              onClick={() => setExpanded((e) => !e)}
              data-test-id="material-storage-container-expand-button"
            />
          ) : null
        }
      >
        <Silos silos={silos} materials={materials} loading={isLoading} />
      </ContentBox>
    )
  }
)
