import {EquipmentData, EquipmentIdType, equipmentIdTypes} from '../types'

// Quick fix for equipment sap numbers with '_' in id and text fields
const transformEquipmentString = (str: string) => str.replaceAll('_', '%%')
const transformBackEquipmentString = (str: string) => str.replaceAll('%%', '_')

export const equipmentToParameter = (equipment: EquipmentData): string => {
  if (!equipment.id || !equipment.idType || !equipment.text)
    throw new Error('incomplete equipment parameter')
  return `${equipment.idType}_${transformEquipmentString(equipment.id)}_${transformEquipmentString(
    equipment.text
  )}`
}

export const parameterToEquipment = (parameter?: string): EquipmentData | undefined => {
  if (!parameter) return

  const input = parameter.split('_')
  const idType = input[0] as EquipmentIdType
  const id = transformBackEquipmentString(input[1])
  const text = transformBackEquipmentString(input[2])

  if (input.length === 3 && equipmentIdTypes.includes(idType) && id && text) {
    return {
      idType,
      id,
      text
    }
  } else {
    throw new Error('invalid equipment parameter')
  }
}
