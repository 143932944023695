import {Close} from '@mui/icons-material'
import {LoadingButton} from '@mui/lab'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material'
import React, {ReactNode} from 'react'
import {useTranslation} from 'react-i18next'

import {getTranslationKey} from '../../utils/translation.utils'

type ConfirmationDialogProps = {
  onClose: () => void
  onDeleteSingle: () => void
  onDeleteSeries?: () => void
  title?: string
  text?: string | ReactNode
  isSeriesItem: boolean
  isSingleLoading?: boolean
  isSeriesLoading?: boolean
  onlyDeleteSeries?: boolean
  translationPrefix?: string
  additionalMessage?: string
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (props) => {
  const {t} = useTranslation()

  return (
    <Dialog data-test-id="event-delete-modal" onClose={props.onClose} open>
      <DialogTitle>
        {t(getTranslationKey('shiftEvent.action.delete', props.translationPrefix))}
      </DialogTitle>
      {props.title && (
        <DialogContent>
          <Typography gutterBottom>
            {t(getTranslationKey('shiftEvent.sureToDelete', props.translationPrefix))}:{' '}
            <b>{props.title}</b>
          </Typography>
          {props.additionalMessage && <Typography>{props.additionalMessage}</Typography>}
        </DialogContent>
      )}
      {props.text && <DialogContent>{props.text}</DialogContent>}
      <DialogActions>
        <Button startIcon={<Close />} variant="text" onClick={props.onClose}>
          {t(getTranslationKey('shiftEvent.action.cancel', props.translationPrefix))}
        </Button>

        {!props.onlyDeleteSeries && (
          <LoadingButton
            loading={props.isSingleLoading}
            onClick={props.onDeleteSingle}
            data-test-id="event-single-delete-button"
            color="error"
            variant="text"
            sx={(theme) => ({boxShadow: theme.shadows[4]})}
          >
            {props.isSeriesItem
              ? t(getTranslationKey('shiftEvent.action.deleteEvent', props.translationPrefix))
              : t(getTranslationKey('shiftEvent.action.delete', props.translationPrefix))}
          </LoadingButton>
        )}

        {props.isSeriesItem && props.onDeleteSeries && (
          <LoadingButton
            loading={props.isSeriesLoading}
            onClick={props.onDeleteSeries}
            data-test-id="event-series-delete-button"
            color="error"
            variant="text"
            sx={(theme) => ({boxShadow: theme.shadows[4]})}
          >
            {t(getTranslationKey('shiftEvent.action.deleteSeries', props.translationPrefix))}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  )
}
