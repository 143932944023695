import {
  DateFormat,
  Iso8601,
  IsoWeekDay,
  TimeZone,
  sortWeekDaysLocaleDependent
} from '@hconnect/uikit'
import {isEmpty} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

const taskRepetitionUnits = ['daily', 'weekly', 'monthly', 'yearly'] as const
export type TaskRepetitionUnit = (typeof taskRepetitionUnits)[number]

const DEFAULT_NUMBER_OF_PREVIOUS_ITEMS = 3

type Props = {
  dates: Iso8601[]
  repetition: TaskRepetitionUnit
  timezone: TimeZone
  numberOfPreviewItems?: number
  weekDays?: IsoWeekDay[]
}

export const TaskRepetitionPreview: React.FC<Props> = ({
  dates,
  repetition,
  timezone,
  weekDays,
  numberOfPreviewItems = DEFAULT_NUMBER_OF_PREVIOUS_ITEMS
}) => {
  const {t, i18n} = useTranslation()

  const next: React.ReactNode[] = dates.map((date: Iso8601, i: number) => (
    <span key={date} data-test-id={`date-preview-${i}`}>
      <DateFormat date={date} timezone={timezone} />
      {dates.length > i + 1 && ', '}
    </span>
  ))

  // if there are more items than shown, add three dots
  if (dates.length > numberOfPreviewItems) {
    next[numberOfPreviewItems] = '...'
  }

  const renderRepetitionRhythmDays = (weekDays: IsoWeekDay[]) => {
    const days = sortWeekDaysLocaleDependent(i18n.language, weekDays)
      .map((day) => t(`datePicker.weekdaysShort.${day}`))
      .join(' & ')
    return `, ${days}`
  }

  return (
    <>
      {t(`datePicker.recurring.${repetition}`)}
      {weekDays && !isEmpty(weekDays) && renderRepetitionRhythmDays(weekDays)} (
      {t('datePicker.nextOccurrences')}: {next})
    </>
  )
}
