import {TaskRepetition, TaskRepetitionDto} from '../types'

export const mapTaskRepetitionToTaskRepetitionDto = (
  repetition: TaskRepetition
): TaskRepetitionDto => ({
  ...repetition,
  ...('startDate' in repetition
    ? {
        startDate: repetition.startDate.toISOString(),
        ...(repetition.endDate && {
          endDate: repetition.endDate?.toISOString()
        })
      }
    : {})
})
